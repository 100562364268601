import React, { ReactNode, Fragment } from "react";
import { HeadFC, Script } from "gatsby";
import { queryResult } from "../data/graphql/query";
import { getGatsbyImageNode } from "../../utils/getGatsbyImageNode";

import LayoutLibrary from "@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-with-alert-24h";
import ClientAreaButton from "@flyvet/flyvet-react-library/dist/src/components/buttons/client-area-button";
import "../styles/variables.scss";

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: any) => {
  const {
    companyName,
    city,
    logoTopbar,
    logoFooter,
    GA4,
    facebook,
    instagram,
    linkedin,
    landingSubtitle,
  } = queryResult();
  const renderButton24h = (trackEvent: any) => (
    <div style={{ padding: 8 }}>
      <ClientAreaButton
        eventCategory="click_ligar"
        clickEventName="click_plantao"
        onClick={() => trackEvent({ action: "click_plantao" })}
        containerLabel="topbar-button"
        btnLabelTop="Plantao 24H"
        btnLabelBottom="(13) 3364 -1332"
        clientArealink="tel:+551333641332"
      ></ClientAreaButton>
    </div>
  );
  return (
    <Fragment>
      <LayoutLibrary
        phone="551333641332"
        renderButton24h={renderButton24h}
        logoTopbar={getGatsbyImageNode(
          logoTopbar,
          `Logo ${companyName}`,
          true,
          false,
          false
        )}
        logoFooter={getGatsbyImageNode(
          logoFooter,
          `Logo ${companyName}`,
          true,
          true,
          true
        )}
        companyName={companyName}
        city={city}
        slogan={landingSubtitle}
        badge={{ type: "vet" }}
        facebook={facebook}
        instagram={instagram.user}
        linkedin={linkedin}
        navItems={[
          {
            label: "Home",
            path: "/",
          },
          {
            label: "Serviços",
            path: "/servicos",
          },
          {
            label: "Galeria",
            path: "/galeria",
          },
          {
            label: "Contato",
            path: "/contato",
          },
        ]}
      >
        {children}
      </LayoutLibrary>
    </Fragment>
  );
};

export default Layout;

export const Head: HeadFC<{}> = ({}) => (
  <>
    <link
      key="nunito"
      rel="preload"
      href="/src/styles/fonts/nunito-v26-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
    <link
      key="titillium-web"
      rel="preload"
      href="/src/styles/fonts/titillium-web-v17-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
  </>
);
